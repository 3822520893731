import { useCompleteSSORedirectGetSessionTransferTokenMutation } from "@/authentication/redirect/util/__generated__/useCompleteSSORedirectGetSessionTransferTokenMutation.graphql"
import {
  LoginSource,
  useCompleteSSORedirectLoginMutation,
} from "@/authentication/redirect/util/__generated__/useCompleteSSORedirectLoginMutation.graphql"
import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { sendSentryAnException } from "@/core/sentryHandler"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import { SocialLoginDestination } from "@components/social-login/SocialLogin"
import { useQueryParams } from "@utils/url/urlUtils"
import { useState } from "react"
import { graphql } from "relay-runtime"
import { decodeBase64, encodeBase64 } from "stream-chat"
import { useCompleteSSORedirectVerifyMutation } from "./__generated__/useCompleteSSORedirectVerifyMutation.graphql"

export type CompleteSSOLoginParams = {
  token: string
  stytch_token_type: string
  slug: string
  destination: string
  loginSource: string
  action?: string
}

export type SSOLoginState = {
  state: string
  message?: string
}

export function useCompleteSSORedirect() {
  const { token, destination, stytch_token_type, loginSource, action } =
    useQueryParams<CompleteSSOLoginParams>()

  const [status, setStatus] = useState<SSOLoginState>({ state: "pending" })
  const userTimezone = useUserTimezone()

  const loginMutation = Relay.useAsyncMutation<useCompleteSSORedirectLoginMutation>(
    graphql`
      mutation useCompleteSSORedirectLoginMutation($input: CompleteSSOLoginInput!) {
        completeSSOLogin(input: $input) {
          redirectOverride
          errors {
            field
            message
          }
        }
      }
    `
  )

  const verifyMutation = Relay.useAsyncMutation<useCompleteSSORedirectVerifyMutation>(
    graphql`
      mutation useCompleteSSORedirectVerifyMutation($input: VerifyAuthProviderInput!) {
        verifyAuthProvider(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `
  )

  const getExchangeTokenMutation =
    Relay.useAsyncMutation<useCompleteSSORedirectGetSessionTransferTokenMutation>(
      graphql`
        mutation useCompleteSSORedirectGetSessionTransferTokenMutation($domain: String!) {
          getSessionTransferToken(domain: $domain) {
            data
          }
        }
      `
    )

  async function completeSSORedirect(): Promise<void> {
    // Check that the token type is correct
    if (stytch_token_type !== "sso") {
      setStatus({ state: "error", message: "Invalid request [001]." })
      return
    }

    if (action === "verify") {
      await completeSSOVerification()
      return
    }

    await completeSSOLogin()
  }

  async function completeSSOLogin(): Promise<void> {
    if (!loginSource) {
      setStatus({ state: "error", message: "Invalid request [002]." })
      return
    }

    // Extract the destination information
    const loginDestination: SocialLoginDestination = JSON.parse(
      decodeBase64(decodeURIComponent(destination))
    )

    // We should ALWAYS have a location state that we are redirecting to for SSO since the redirect
    // will be added as the destination when clicking the SSO button
    if (!loginDestination.locationState?.redirectUrl) {
      setStatus({ state: "error", message: "Invalid request [003]." })
      return
    }

    let dest = loginDestination.locationState.redirectUrl!

    try {
      const res = await loginMutation({
        input: {
          token,
          timezone: userTimezone,
          loginSource: loginSource as LoginSource,
        },
      })
      if (res.completeSSOLogin.errors?.length) {
        for (const error of res.completeSSOLogin.errors) {
          if (error.field === "ssoLogin") {
            setStatus({ state: "error", message: error.message })
            return
          } else if (error.field === "tokenInvalid") {
            // The user has already used the token and maybe hit back one too many times
            // so we will just redirect them to the app root
            window.location.href = BASE_DOMAIN_URL
            return
          }
        }
        setStatus({
          state: "error",
          message: "An error occurred when logging you in, please try again later [001].",
        })
        return
      }

      // If the backend wants us to override the redirect, let's do that now
      // This is mainly used for allowing just in time provisioning
      dest = res.completeSSOLogin.redirectOverride || dest
    } catch (error) {
      setStatus({
        state: "error",
        message: "An error occurred when logging you in, please try again later [002].",
      })
      // Send the error to sentry
      sendSentryAnException(error, {
        extra: {
          title: "useCompleteSSORedirect",
        },
      })
      return
    }

    // If the login destination was for a custom domain, then we need to setup an exchange token
    // using the session we now have to be redeemed by a redirect
    const u = new URL(dest)
    if (isDiscoDomain(u.host)) {
      window.location.href = dest
      return
    }

    const redirectDestination = encodeBase64(
      JSON.stringify({
        locationState: {
          redirectUrl: dest,
        },
      })
    )

    // Get the token exchange
    const { getSessionTransferToken } = await getExchangeTokenMutation({
      domain: u.host,
    })
    if (!getSessionTransferToken.data) {
      setStatus({
        state: "error",
        message: "An error occurred when logging you in, please try again later [003].",
      })
      return
    }

    // Setup the redirect
    window.location.href = `https://${u.host}/auth/exchange?exchangeToken=${getSessionTransferToken.data}&destination=${redirectDestination}`
  }

  async function completeSSOVerification(): Promise<void> {
    try {
      const res = await verifyMutation({ input: { token } })
      if (res.verifyAuthProvider.errors?.length) {
        for (const error of res.verifyAuthProvider.errors) {
          if (error.field === "authProvider") {
            setStatus({ state: "error", message: error.message })
            return
          }
        }
      }

      // FUTURE: Redirect to the redirect URL we set
    } catch (error) {
      setStatus({
        state: "error",
        message: "An error occurred when verifying you, please try again later [002].",
      })
      // Send the error to sentry
      sendSentryAnException(error, {
        extra: {
          title: "completeSSOVerification",
        },
      })
    }
  }

  return { completeSSORedirect, status }
}
